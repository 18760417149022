.home {
    display: flex;
    border-radius: 20px;
    flex-direction: row-reverse;
    font-family: 'Cairo-Medium' !important;
}

.homeContainer {
    flex: 6;
    font-family: 'Cairo-Medium' !important;
    margin-right:  370px;
}
.h2{
    font-family: 'Cairo-Medium' !important;
    text-align: center;
    color: #0c488d;
    font-size: 40px;
}
.activities{
    display: flex;
    column-gap: 30px;
    text-align: right;
    justify-content: center;
    

}
.activities__body{
    background-color: #0178ff;
    color: #fff;
    text-align: center;
    border-radius:24px ;
    width: 300px;
    height: 140px;
    flex-shrink: 0;
    padding-top: 5px;
    -webkit-border-radius:24px ;
    -moz-border-radius:24px ;
    -ms-border-radius:24px ;
    -o-border-radius:24px ;
    
}
.activities__body p{
    font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Cairo-Medium' !important;
        margin-bottom: 0 !important;
}
.activities__body h2{
    font-size: 38px;
        font-style: normal;
        font-weight: 800;
        margin-top: 0 !important;
      
}
.pie__row{
    display: flex;
    align-items: center !important;
    column-gap: 130px !important;
    margin-top: 50px;
    margin-bottom: 150px;
    justify-content: center;
}
.pie__row h2{
    text-align: center !important;
}
.h1{
    display: flex;
    justify-content: center;
    margin-top: 350px;
    font-size: 60px;
    color: red;
}
.block{
    display: block;
    margin-bottom: 20px;
}
.dash{
    padding-left: 30px;
    margin-top: 50px !important;
    width: 100%;
    
    }
    .dash h4{
        font-weight: bold;
        text-align: right;
        font-family: 'Cairo-Medium' !important;
    }
    .col__date {
        display: flex;
        column-gap: 15px;
     }
     .btnshare{
text-align: left;
display: flex;
flex-direction: column !important;
justify-content: center !important;
    }
    .btnshare img{
        width: 40px;
        margin: auto;
        animation-name: bounce;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        position: relative;
    }
    .div__share{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @keyframes bounce {

        from {
          top: 10px;
          animation-timing-function: ease-out;
        }
      

      
        75% {
          top: 50px;
          animation-timing-function: ease-in;
        }
      
        to {
          top: 10px;
        }
      
      }