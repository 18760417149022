 .home {
    display: flex;
    border-radius: 20px;
    flex-direction: row-reverse;
}

.homeContainer {
    flex: 6;
    font-family: 'JosefinSans-Regular' !important;
    margin-right:  330px;
}
.h2{
    font-family: 'Cairo-Medium' !important;
    text-align: center;
    color: #0c488d;
    font-size: 40px;
}
.overviewOrFacilities{
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
}
.overviewOrFacilities__body,
.overviewOrFacilities__date{
    display: flex;
    align-items: center;
    column-gap: 30px;
}
.location{
    display: flex;    
}
.style__link{
    color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        border-bottom: 2px solid #000;
        cursor: pointer;
}
.view__link{
    color: rgba(137, 137, 137, 0.40);
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        cursor: pointer;
}
.select{
    width: 200px !important;
    padding-left: 40px !important;
    border-radius: 35px !important;
    background-color: rgba(151, 151, 151, 0.14) !important;
    -webkit-border-radius: 35px !important;
    -moz-border-radius: 35px !important;
    -ms-border-radius: 35px !important;
    -o-border-radius: 35px !important;
}
.select:focus{
        /*background-color: #f5f0f0cb;
        */box-shadow: 0 0 0 0.25rem transparent !important;
        border: none !important;
        transform: scale(1.00);
        box-shadow: 0 0 0 0.25rem transparent !important;
        -webkit-transform: scale(1.00);
        -moz-transform: scale(1.00);
        -ms-transform: scale(1.00);
        -o-transform: scale(1.00);
}
.today{
    position: relative;
}
.today__img{
    position: absolute;
    top: 8px;
    left: 10px;
    width: 20px;
}
.location{
      background-color: rgba(151, 151, 151, 0.14) !important;
      border-radius:35px ;
      -webkit-border-radius:35px ;
      -moz-border-radius:35px ;
      -ms-border-radius:35px ;
      -o-border-radius:35px ;
      padding: 10px 20px;
      cursor: pointer;
}
.location img{
    width: 20px;
    height: 18px;
}
.location p{
    margin-bottom: 0 !important;
}
.overviewbar{
    display: flex;
    padding-left: 25px;
    margin-top: 30px;
}
.linear,
.overview__bar{
            width: 580px;
            box-shadow: var(--color-shadow);
            padding: 15px 15px 0 15px;
            border-radius: 8px;
            background-color: var(--secondary-background-color);
            color: var(--text-color);
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
}
.linear__body{
    display: flex;
    align-items: center !important;
    justify-content: right !important;
    border-radius: 50px;
    background-color: rgba(137, 137, 137, 0.08);
    width: 70px;
    padding: 5px;
    text-align: right !important;
}
.linear__body p{
    margin-bottom: 0;
}
.overview__line{
width: 580px;
height: 370px;
    box-shadow: var(--color-shadow);
    padding: 15px 15px 0 15px;
    border-radius: 12px;
    background-color: var(--secondary-background-color);
    color: var(--text-color);
    margin-top: 30px;
}
.overview__charts{
    display: flex;
    align-items: center;
    column-gap: 50px;
}
.lineimg {
    height: 200px !important;
}
.chart__body{
    text-align: center;
}
.chart__body h3{
    color: #000;
        font-size: 42px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
}
.chart__body p{
    color: #000;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.block{
    display: block;
    margin-bottom: 20px;
}
.none{
    display: none;
}
.tab{
    margin-left: 25px !important;
    width: 97% !important;
    margin-top: 20px !important;
    font-family: 'JosefinSans-Regular' !important;
}
.tab__head{
    background-color: rgba(217, 217, 217, 0.27) !important;
    
    
}
.tab__cell{
color: #000 !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
    font-family: 'JosefinSans-Regular' !important;
    padding: 25px 0 !important;
}
.r{
    font-family: 'JosefinSans-Regular' !important;
}
.row{
    display: flex !important;
    column-gap: 10% !important;
    padding-top: 15px;
}
.facility__bar{
    width: 900px !important;
}
.collapsetab{
    border-bottom: 2px solid rgba(217, 217, 217, 0.27) !important;
}
.review{
font-family: 'JosefinSans-Regular' !important;
}

.recent{
    display: flex;
    column-gap: 15px;
}
.recent p{
    color: #898989;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 10px;
       
}
.review h4{
    color: #000;
        font-size: 17px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: 0.277px !important;
}
.t{
    background-color: #000 !important;
    color: #fff !important;
}
.recent__title {
    color: #000;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal !important;
}
@media (min-width:0) and (max-width:600px) {
        .home {
                display: flex;
                flex-direction: column-reverse;
                margin: 0 20px;
            }
    .overviewOrFacilities,
    .overview__charts,
    .row{
        display: flex;
        flex-direction: column;
    }
    .overviewbar,
    .linear,
    .overview__line,
    .tab{
        width: 100%;
        padding: 0;
    }
    .tab{
        margin-left: 6px !important;
    }
    .linear__body{
        margin: 10px !important;
    }
.overview__bar{
padding: 0 !important;
}
.tab__cell{
padding-right: 50px !important;
}
}
.table__body{
padding: 30px;
}
.col__date {
   display: flex;
   column-gap: 15px;
}