:root {
  --primary-color: #131313;
  --secondary-background-color: #fff;
  --text-color: #000;
  --second-text: #000;
  --background-content:#F3F3F3;
  --background-price: #FFF6ED;
  --color-price: #FF8201;
  --color-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
[data-theme="dark-mode"] {
  --main-background-color: #000;
  --text-color: #fff;
  --secondary-background-color: #303030;
  --second-text:#777;
  --background-content: #454545;
  --background-price:#454545;
  --color-price:#fff;
  --color-shadow: 0 4px 8px 0 rgba(187, 184, 184, 0.2), 0 6px 20px 0 rgba(187, 184, 184, 0.2);
}

body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color) !important;
    color: var(--text-color) !important;
    direction: rtl;
}
.MuiDataGrid-columnHeaderTitle{
  color:var(--text-color) ;
  font-family: 'Cairo-Medium' !important
}
.MuiDataGrid-cellContent,
.cellWithStatus{
  color: var(--second-text);
  font-family: 'Cairo-Medium' !important
}
select{
  background-color:var(--secondary-background-color) !important ;
  color: var(--text-color) !important;
}
code {
  font-family: "Cairo", sans-serif;
}

@font-face {
  font-family: 'JosefinSans-Regular';
  src: url(/src/assets/font/Josefin_Sans/static/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: 'Cairo-Medium';
  src: url(/src/assets/font/Cairo/Cairo-Medium.ttf);
}

@font-face {
  font-family: 'Cairo-Bold';
  src: url(/src/assets/font/Cairo/Cairo-Bold.ttf);
}

.inputBox_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 800px !important;
  width: fit-content;
  height: fit-content;
  padding: 5px 15px;
border-radius: 8px;
  background-color: rgba(217, 217, 217, 0.27);
  overflow: hidden;
  border: none !important;
  outline: none !important;
}
.inputBox:hover{
  border: none !important;
  outline: none !important;
}
.inputBox:focus{
    border: none !important;
      outline: none !important;
}

.search_icon {
  height: 1em;
  padding: 0 0.5em 0 0.8em;
  fill: #abb2bf;
}

.inputBox {
  background-color: transparent;
  color: #000;
  outline: none !important;
  width: 100%;
  border: none !important ;
  padding: 0.5em 1.5em 0.5em 0;
  font-size: 1em;
}

input::placeholder {
  color: #000 !important;
}


.button {
  --color: #fff;
  padding: 0.5em 1.7em;
  background-color: transparent;
  border-radius: .3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid #000;
  font-family: inherit;
  color: var(--color);
  z-index: 1;
  background-color: #000;
}

.button::before,
.button::after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button::before {
  top: -1em;
  left: -1em;
}

.button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
  height: 410px;
  width: 410px;
}

.button:hover {
  color: rgb(10, 25, 30);
}

.button:active {
  filter: brightness(.8);
}
@media (min-width:0) and (max-width:600px) {
 
      .inputBox_container{
        width: 100% !important;
      }
  
}
.css-hsi95o-MuiTableRow-root td,
.MuiTableCell-root, .MuiTableRow-root td{
  text-align: right !important;
}
.css-1atu56z-MuiPaper-root,
.css-d7xo1i {
  direction: rtl !important;
}
select,
label{
  font-family: 'Cairo-Medium' !important
}
html[dir='rtl'] .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  transform: rotate(180deg) !important;
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
}
.apexcharts-xaxis-texts-g{
  transform: translate(-50px,50px) !important;
  -webkit-transform: translate(-50px,50px) !important;
  -moz-transform: translate(-50px,50px) !important;
  -ms-transform: translate(-50px,50px) !important;
  -o-transform: translate(-50px,50px) !important;
}
:not(body):has(>.driver-active-element) {
  overflow: visible !important;
}
@media (min-width:0px) and (max-width:600px) {
  .form-control{
    width: fit-content !important;
  }
  .row{
    width: 100% !important;
  }
  .offcanvas.offcanvas-end{
    width: 100% !important;
  }
}
@media (min-width:1400px) {
  .navres{
    display: none !important;
  }
}