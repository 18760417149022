.m_9bdbb667{
  font-family: 'Cairo-Medium' !important;
  color: #000 !important;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 17px !important;
  padding: 20px 15px !important;
  line-height: 1.625rem !important;
}
.active  {
  text-decoration: none !important;
}
.active .m_4ba554d4{
  color: #0178ff !important;
  font-size: 20px;
  font-weight: bold;

}
a {

  text-decoration: none !important;
}
a .m_4ba554d4{
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
a .m_4ba554d4:hover{
  color: #0178ff !important;
}
/* From Uiverse.io by Donewenfu */ 
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: '';
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #076fe5;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}
.MuiTableCell-root,
.MuiTableRow-root td{
  font-family: 'Cairo-Medium' !important;
}
.offcanvas.offcanvas-end{
  border: none !important;
}
.offcanvas-backdrop.show{
  opacity: 0 !important;
}
.highcharts-credits{
  display: none !important;
}
.offcanvas-backdrop{
  width: 100% !important;
  position: static !important;
}
.highcharts-title,
.highcharts-text-outline
{
  font-family: 'Cairo-Medium' !important;
}
.username{
  font-weight: bold;
  font-size: 17px;
}
.css-hsi95o-MuiTableRow-root td {
  font-size: 17px !important;
}
body[dir='rtl'] .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  transform: rotate(180deg) !important;
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
}
:not(body):has(>.driver-active-element) {
  overflow: visible !important;
}