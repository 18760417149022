
    .sidebar {
        background-color: #fff !important;
        transition: all .5s ease-in-out !important;
        padding-top: 20px !important;
        width: 270px !important;
        font-family: 'Cairo-Medium'!important;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
        -webkit-transition: all .5s ease-in-out !important;
        -moz-transition: all .5s ease-in-out !important;
        -ms-transition: all .5s ease-in-out !important;
        -o-transition: all .5s ease-in-out !important;
}

    .sidebar:hover .span {
        transition: all .5s ease-in-out;
    }

    .csnter{
        position: sticky !important;
        top: 0 !important;
    }
    .top {
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px !important;
    }

    .logo {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-top: 20px
    }

    hr {
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }



    ul {
        list-style: none;
        margin: auto;
        padding: 0px !important;
       
    }

    .title {
        font-size: 10px;
        font-weight: bold;
        color: #fffefe !important;
        margin-top: 15px;
        margin-bottom: 5px;
        display: none;
    }

    .li {

        cursor: pointer !important;
padding: 0 30px;
        color: #000 !important;

        font-size: 17px !important;

        line-height: 1.625rem !important;
 
        text-align: right !important;
    }


    .span {
        font-size: 15px;
        font-weight: 600;
        color: #000 !important;
        margin-left: 10px;
        opacity: 1;
        transition: all .5s ease-in-out;
        width: 10;
        text-align: center;
        border: none;
        background-color: transparent;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        cursor: pointer !important;
        
    }

         .banner__ico{
            width: 24px;
            height: 24px;
         }

.acc__body span,
.acc__body div{
    text-align: right;
    font-family: 'Cairo-Medium' !important;
    color: #000 !important;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 17px !important;
    line-height: 1.625rem !important;

}
.acc__control span{
    font-family: 'Cairo-Medium' !important;
    color: #000 !important;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 17px !important;
    line-height: 1.625rem !important;
    font-weight: bold;

}
.logo{
    width: 100%;
}
.username{
    display: flex !important;
    justify-content: space-between !important;
flex-direction: column !important;
}
@media (min-width:0px) and (max-width:600px) {
    .center,
    .sidebar{
        display: none !important;
    }
    ul{
        margin: 0 !important;
    }
}