.home {
    display: flex;
    border-radius: 20px;
    font-family: 'Cairo-Medium' !important;
    flex-direction: row-reverse;
}
.homeContainer {
    flex: 6;
    font-family: 'Cairo-Medium' !important;
    margin-right:  370px;
}
.leftcon{
    display: flex;
    column-gap: 40px;

}
.updown{
    margin-top: 30px;
}
.rstatus{
    margin-left: 30px !important;
}
.status{
            box-shadow: var(--color-shadow);
                padding: 40px 15px 30px 15px;
                border-radius: 8px;
                background-color: var(--secondary-background-color);
                color: var(--text-color);
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
                width: 96%;
}
.r2{
    width: 100%;
}
.up{
            box-shadow: var(--color-shadow);
                padding: 40px 15px 30px 15px;
                border-radius: 8px;
                background-color: var(--secondary-background-color);
                color: var(--text-color);
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
                width: 380px;
                margin-bottom: 20px;
}
.up div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tabcontrol{
    background-color:rgba(217, 217, 217, 0.27) ;
    
}
.tabs{
    margin-top: 30px;
}
.tabcontrol__title{
    color: #000 !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: 0.277px !important;
}
.tab__para p{
    margin-bottom: 0;
}
.high{
        color: #fff !important;
        font-size: 17px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: 0.277px !important;
        background-color: rgb(170, 12, 12);
        padding: 5px  !important;
        border-radius:8px ;
        -webkit-border-radius:8px ;
        -moz-border-radius:8px ;
        -ms-border-radius:8px ;
        -o-border-radius:8px ;
padding: 10px 0 !important;
        width: 70% ;
        text-align: center !important;
}
.medium{
        color: #fff !important;
            font-size: 17px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
            letter-spacing: 0.277px !important;
            background-color: #FCC536;
            padding: 5px !important;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            padding: 10px 0 !important;
            width: 70%;
            text-align: center !important;
}
.tab__para1{
    color: #848A9C !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 800 !important;
        line-height: 19px !important;
        /* 158.333% */
        letter-spacing: 0.184px !important;
}
.tab__para2{
    color: #848A9C !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 19px !important;
        letter-spacing: 0.184px !important;
}
.name{
    color: #000 !important;
        font-size: 17px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: 0.277px !important;
}
.r{
    width: 100%;
}
.response{
    display: flex;
    margin-left: 10px;
    column-gap: 20px;
}
.response p{
    border: 1px solid #C4C8D3;
    padding: 5px 15px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.controlchart{
    width: 50%;
        box-shadow: var(--color-shadow);
            padding: 20px 15px 15px 15px;
            border-radius: 8px;
            background-color: var(--secondary-background-color);
            color: var(--text-color);
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
margin-left: 30px;
margin-top: 30px;
        }
        .homeContainer {
            flex: 6;
            font-family: 'Cairo-Medium' !important;
            margin-right:  370px !important;
        }
.h2{
    font-family: 'Cairo-Medium' !important;
    text-align: center;
    color: #0c488d;
    font-size: 40px;
}
.style__link {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-bottom: 2px solid #000;
    cursor: pointer;
}

.view__link {
    color: rgba(137, 137, 137, 0.40);
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: pointer;
}
.overviewOrFacilities {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
}

.overviewOrFacilities__body,
.overviewOrFacilities__date {
    display: flex;
    align-items: center;
    column-gap: 30px;
}
.block{
    display: block;
}
.none{
    display: none;
}
.location {
        display: flex;
    background-color: rgba(151, 151, 151, 0.14) !important;
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    padding: 10px 20px;
    cursor: pointer;
}

.location img {
    width: 20px;
    height: 18px;
}

.location p {
    margin-bottom: 0 !important;
}
.total{
    display: flex;
   margin-top: 70px;
   column-gap: 15px !important;

}
.dash{
padding-left: 30px;
margin-top: 50px !important;
width: 100%;

}
.dash h4{
    font-weight: bold;
    text-align: right;
    font-family: 'Cairo-Medium' !important;
}
.activities{
    display: flex;
    column-gap: 30px;
    text-align: right;
    justify-content: center;
    

}
.activities__body{
    background-color: #0178ff;
    color: #fff;
    text-align: center;
    border-radius:24px ;
    width: 230px;
    height: 126px;
    flex-shrink: 0;
    padding-top: 25px;
    -webkit-border-radius:24px ;
    -moz-border-radius:24px ;
    -ms-border-radius:24px ;
    -o-border-radius:24px ;
    
}
.activities__body p{
    font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Cairo-Medium' !important;
        margin-bottom: 0 !important;
}
.activities__body h2{
    font-size: 38px;
        font-style: normal;
        font-weight: 800;
        margin-top: 0 !important;
      
}
/* .transit p{
    color: #26E2B3;
        text-shadow: 0px -1px 33px rgba(26, 190, 255, 0.50);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.transit h2{
    color: #26E2B3;
        text-shadow: 0px -1px 33px rgba(26, 190, 255, 0.50);
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
} */
.type{
    display: flex;
    text-align: center;
}
.linestyle{
    width: 680px;
    height: 300px;
    box-shadow: var(--color-shadow);
        padding: 15px 15px 0 15px;
        border-radius: 12px;
        background-color: var(--secondary-background-color);
        color: var(--text-color);
}
.lineimg{
    height: 200px !important;
}
.linestyle__body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C8D3;
}
.linestyle__body h2{
    font-weight: bold;
}
.linestyle__body p{
    border: 1px solid #C4C8D3;
    color: #C4C8D3;
    padding: 10px 35px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    cursor: pointer;
}
.dashchart{
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
    margin-top: 50px;
}
.lastbar {
    width: 470px !important;
    height: 300px;
        box-shadow: var(--color-shadow);
            padding: 15px;
            border-radius: 12px;
            background-color: var(--secondary-background-color);
            color: var(--text-color);
}
.routes{
    background-color: #f2f8ff;
    padding: 0 25px;
}
.routes h2{
    padding: 50px 15px;
    font-weight: bold;
    font-size: 27px;
}
.input {
    width: 570px;
    background-color: #fff;
    color: #242424;
    padding: .15rem .5rem;
    min-height: 40px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #BBB;
    line-height: 1.15;
    box-shadow: 0px 10px 20px -18px;
}

input:focus {
    border-bottom: 2px solid #fff;
    border-radius: 4px 4px 2px 2px;
}

input:hover {
    outline: 1px solid lightgrey;
}
.bar{
    position: relative;
    margin-bottom: 25px;
}
.local{
    width: 20px;
}
.routes__body{
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 20px;
}
.ell{
    position: absolute;
        background-color: #0178ff;
        width: 10px;
        height: 10px;
        text-align: center;
        padding-top: 5px;
        font-size: 12px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        font-family: "Changa-Regular";
        top: 0px;
        color: #fff;
}
.b {
    position: absolute;
    background-color: #0178ff;
    width: 30px;
    height: 30px;
    text-align: center;
padding-top: 5px;
    font-size: 12px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-family: "Changa-Regular";
    top: -10px;
    color: #fff;
}
.routes__name{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.routes__name h3{
    color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.arrive{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.from p,
.to p{
    color: #BBB;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.from h3,
.to h3{
    color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
}
.load__details{
    background-color: var(--secondary-background-color);
    padding: 15px;
    box-shadow: 0 0 10px rgba(70, 69, 69, 0.2);
    width: 190px;
    text-align: center;
    height: 245px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.allloard{
    background-image: linear-gradient(to bottom right, #0178ff,#71baff);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    color: #fff;
    margin-bottom: 90px;
}
.allloard__para{
    text-align: left;
    padding: 8px;
    margin-bottom: 0 !important;
    text-transform: capitalize;
}
.loardnum{
    padding-bottom: 5px;
}
.view__btn{
      color: #0178ff;
      text-decoration: none;
      background-color: var(--background-content);
      padding: 10px 30px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
} 
.loadprog,
.loaddash,
.totalexpenses{
    padding-top: 10px;
}
.loadprog__title,
.loaddash__title,
.totalexpenses__title{
    color: #0178ff;
    font-weight: bold;
}
.loadprog__para,
.loaddash__para,
.totalexpenses__para{
    color: #0178ff;
    text-transform: capitalize;
    background-color: #FFF6ED;
    padding: 3px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 12px;
}
.loadprog__desc{
    font-size: 12px;
    color: #777;
    margin-bottom: 40px;
}
.loaddash__para{
    background-color: #F0F9F0;
    color: #67C568;
    margin-bottom: 85px !important;
}
.totalexpenses__para{
    margin-bottom: 85px !important;
}
.booking{
    box-shadow: 0 0 10px rgba(70, 69, 69, 0.2) !important;
    width: 445px !important;
    height: 245px !important;
    margin-top: 70px !important;
    border-radius: 12px !important ;
    -webkit-border-radius: 12px !important ;
    -moz-border-radius: 12px !important ;
    -ms-border-radius: 12px !important ;
    -o-border-radius: 12px !important ;
    font-family: 'JosefinSans-Regular' !important;
    background-color: var(--secondary-background-color);
}

.booking__title{
    text-transform: capitalize;
    padding: 15px;
    font-weight: bold;

}
.loadbook{
    display: flex;
    gap: 5%;
    padding-left: 40px;

}
.loads__body{
    background-color: var(--background-content);
    padding: 40px;
    text-align: center !important;
}
.loads__title{
    font-weight: bold;
    color: #0178ff;
}
.loads__para{
    text-align: center !important;
    color: #0178ff;
    text-transform: capitalize;
    margin-top: 5px;
}
.chart{
    box-shadow: 0 0 10px rgba(70, 69, 69, 0.2);
    margin-top: 30px !important;
    border-radius:12px ;
    -webkit-border-radius:12px ;
    -moz-border-radius:12px ;
    -ms-border-radius:12px ;
    -o-border-radius:12px ;
    background-color: var(--secondary-background-color);
}
.chart__body{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-family: 'JosefinSans-Regular' !important ;
    
}
.chart__para{
    text-transform: capitalize;
    font-weight: bold;
}
.chart__status{
    display: flex;
    column-gap: 20px !important;
}
.table{
    box-shadow: 0 0 10px rgba(70, 69, 69, 0.2);
    margin-top: 40px !important;
    border-radius: 12px !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;

    font-family: 'JosefinSans-Regular' !important;
    width: 100% !important;
    background-color: var(--secondary-background-color);
    color: #029F04;
}
.edit__btn{
    background-color: #0178ff;
    color: #fff !important;
    padding: 5px !important;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.col__date {
    display: flex;
    column-gap: 15px;
 }
 @media (min-width:0px) and (max-width:600px) {
    .home {
        display: flex;
        flex-direction: column;
       justify-content: center;
    }
    .homeContainer{
        margin-right:20px !important ;
        justify-content: center;
    }
.overviewOrFacilities,
.activities,
.dashchart,
.leftcon,
.r{
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: 0;
}
.style__link,
.view__link{
    font-size: 27px;
    margin-bottom: 20px;
}
.activities__body{
margin-bottom: 15px;
    width: 100%;

}
.table,
.lastbar,
.linestyle,
.input,
.controlchart,
.up,
.status{
    width: 100% !important;
}
.linestyle{
    margin-bottom: 15px;
}
.controlchart{
    margin: 20px 10px !important;
    padding: 0  !important;
}
.response p{
    font-size: 12px !important;
}
.up,
.tabs{
    margin: 0 10px 15px;
}
.tabs{
    margin-top: 10px;
}
.rstatus,
.r2 {
    margin-left: 10px !important;
    margin-bottom: 15px;
}
.tabcontrol__title{
    font-size: 14px !important;
}
.dash{
    padding-left: 0 !important;
    margin-top: 0 !important;
}
}
.h1{
    display: flex;
    justify-content: center;
    margin-top: 350px;
    font-size: 60px;
    color: red;
}