.home {
    display: flex;
    border-radius: 20px;
    font-family: 'Cairo-Medium' !important;
    flex-direction: row-reverse;
}
.homeContainer {
    flex: 6;
    font-family: 'Cairo-Medium' !important;
    margin-right:  270px;
}
.dash{
    padding-left: 30px;
    margin-top: 50px !important;
    width: 100%;
    
    }
    .dash h4{
        font-weight: bold;
        text-align: right;
        font-family: 'Cairo-Medium' !important;
    }
    .activities{
        display: flex;
        column-gap: 30px;
        text-align: right;
        justify-content: center;
        
    
    }
    .activities__body{
        background-color: #0178ff;
        color: #fff;
        text-align: center;
        border-radius:24px ;
        width: 300px;
        height: 140px;
        flex-shrink: 0;
        padding-top: 25px;
        -webkit-border-radius:24px ;
        -moz-border-radius:24px ;
        -ms-border-radius:24px ;
        -o-border-radius:24px ;
        
    }
    .activities__body p{
        font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: 'Cairo-Medium' !important;
            margin-bottom: 0 !important;
    }
    .activities__body h2{
        font-size: 38px;
            font-style: normal;
            font-weight: 800;
            margin-top: 0 !important;
          
    }
    .block{
        display: block;
        margin-bottom: 20px;
    }
    .col__date {
        display: flex;
        column-gap: 15px;
     }
     .h2{
        font-family: 'Cairo-Medium' !important;
        text-align: center;
        color: #0c488d;
        font-size: 40px;
    }
    .h1{
        display: flex;
        justify-content: center;
        margin-top: 350px;
        font-size: 60px;
        color: red;
    }